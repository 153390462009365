<template>
  <div class="lg:w-1/2 w-full mb-5">
    <div class="flex space-x-3">
      <h1 class="text-2xl font-bold">
        {{ $t("business.structure.title") }}
      </h1>
    </div>
    <p class="mt-4">
      {{ $t("business.structure.description") }}
    </p>
  </div>
  <div class="w-full">
    <form @submit.prevent="businessStructureForm">
      <app-form-country
        :allowFilter="true"
        :showIcon="true"
        :labelText="$t('business.structure.country_label')"
        class="lg:w-7/12 w-full mb-5"
        v-model="business.country"
        :errorMessage="errors.country_id"
        :disabled="!editMode"
      />

      <app-form-input
        id="business.name"
        name="business.name"
        type="text"
        :placeholder="$t('general.name')"
        :labelText="$t('business.structure.info_label')"
        class="lg:w-7/12 w-full"
        v-model="business.name"
        :errorMessage="errors.name"
        :disabled="!editMode"
      />

      <div class="flex flex-col lg:flex-row mb-5 mt-5">
        <div class="flex flex-col lg:w-7/12 w-full">
          <app-form-input
            id="business.address_1"
            name="business.address_1"
            type="text"
            :placeholder="$t('general.address.line_1')"
            :labelText="$t('business.structure.address_label')"
            v-model="business.address_1"
            :errorMessage="errors.address_1"
            :disabled="!editMode"
          />

          <app-form-input
            id="business.address_2"
            name="business.address_2"
            type="text"
            :placeholder="$t('general.address.line_2')"
            v-model="business.address_2"
            :errorMessage="errors.address_2"
            :disabled="!editMode"
          />

          <app-form-input
            id="business.postcode"
            name="business.postcode"
            type="number"
            :placeholder="$t('general.address.postcode')"
            v-model="business.postcode"
            :errorMessage="errors.postcode"
            :disabled="!editMode"
            :numberOnly="true"
          />

          <app-form-input
            id="business.city"
            name="business.city"
            type="text"
            :placeholder="$t('general.address.city')"
            v-model="business.city"
            :errorMessage="errors.city"
            :disabled="!editMode"
          />

          <app-form-state
            :showLabel="false"
            :placeholder="$t('general.address.state')"
            :country_id="business.country?.id ?? 0"
            v-model="business.state"
            :errorMessage="errors.state_id"
            :disabled="!editMode"
          />
        </div>

        <div
          class="
            flex-none
            bg-gray-100
            h-full
            rounded-lg
            pt-3
            pb-3
            pl-2
            pr-2
            text-gray-500
            lg:w-1/3
            w-full
            lg:ml-10
            ml-0
            mt-2
            lg:mt-6
            text-sm
          "
        >
          {{ $t("business.structure.address_info") }}
        </div>
      </div>
      <app-form-type-business
        :labelText="$t('business.structure.type_of_business_label')"
        class="lg:w-7/12 w-full mb-5"
        :country_id="business.country?.id ?? 0"
        v-model="business.type_of_business"
        :errorMessage="errors.type_of_business_id"
        :showLabel="true"
        :allowFilter="false"
        :disabled="!editMode"
      />

      <app-button
        v-if="business.business_status_id == null || editMode"
        type="submit"
        :loading="loading"
        class="mt-10 lg:w-7/12 w-full"
        :showProceedIcon="true"
        >{{ $t("general.continue") }}
      </app-button>
    </form>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      emptyErrors: {
        country: [],
        name: [],
        address_1: [],
        address_2: [],
        postcode: [],
        city_id: [],
        state_id: [],
        type_of_business_id: [],
      },
    };
  },
  computed: {
    loading() {
      return this.$store.getters["masterBusinessStore/loading"];
    },
    business() {
      return this.$store.getters["masterBusinessStore/business"];
    },

    errors() {
      return (
        this.$store.getters["masterBusinessStore/errors"] ?? this.emptyErrors
      );
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
    editMode() {
      return (
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED ||
        this.business.business_status_id == null
      );
    },
  },
  methods: {
    async businessStructureForm() {
      this.resetState();

      const businessResult = await this.$store.dispatch(
        "masterBusinessStore/createOrUpdateMasterBusiness",
        this.business
      );

      if (businessResult == null) return;

      await this.$store.dispatch(
        "masterBusinessStore/getBusinessSupportDocuments",
        businessResult.id
      );
      await this.$store.dispatch(
        "masterBusinessStore/validateBusinessSupportDocuments",
        businessResult.id
      );
      await this.$store.dispatch(
        "masterBusinessStore/typeBusinessSupportDocuments",
        businessResult.id
      );

      this.$router.push({
        path: "/master-business/business/detail",
        query: {
          business_id: businessResult.id,
        },
      });
    },

    resetState() {
      this.$store.commit("masterBusinessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>
